import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { MessageAttachmentCategory } from '@sestek/common/components';
import { MessageSource, MessageType } from 'src/app/shared/chat/chat.model';
import { GridConfig } from 'src/app/shared/column-configurator/models/column-configurator.model';
import { FlowType } from 'src/app/shared/designer/proxy/designer.model';
import { NamedEntitySourceType, NamedEntityType } from 'src/app/virtual-agents/proxy';

export interface GetConversationListDto {
  customerInformationType: CustomerInformationType;
  customerInformation: string;
  projectIds?: string[];
  intentIds?: string[];
  channelNames?: string[];
  ratings?: number[];
  sentimentLevels?: number[];
  startDate?: Date;
  endDate?: Date;
}

export interface ConversationFilterValueModel extends GetConversationListDto {
  conversationId?: string;
}

export interface SessionDto {
  date: string;
  projectName: string;
  externalConversationId: string;
  conversationSessionId: string;
  channelName: string;
  channelId: string;
  duration: number;
  lastIntent: string;
  averageSentimentScore: number;
  sentimentLevel: SentimentLevel;
  conversationSummary: string;
  customerName: string;
  customerEmailAddress: string;
  customerPhoneNumber: string;
  customerTwitterAddress: string;
  lastRatingScore: number;
}

export enum CustomerInformationType {
  Phone,
  Name,
  Email,
  Twitter,
}

export enum SentimentLevel {
  VeryNegative,
  Negative,
  Neutral,
  Positive,
  VeryPositive,
}

export enum SentimentLevelOptions {
  Negative = 1,
  Neutral = 2,
  Positive = 3,
}

export enum TranslationStatus {
  Success,
  Failed,
  Skipped,
}

export interface SessionDetailDto {
  session: SessionDto;
  messages: MessageDto[];
  lastRating: RatingDto;
}

export interface MessageDto {
  id: string;
  text: string;
  translatedText: string;
  dateCreated: Date;
  type: number;
  source: number;
  translationStatus?: number;
  contentType: number;
  storagePath: string;
  jsonContent: string;
  translatedJsonContent: string;
  sentimentScore: number;
  sentimentLevel: SentimentLevel;
  srConfidence: number;
  isSrConfidenceBelowThreshold: boolean;
  hasRecognitionAmbiguity: boolean;
  flowName: string;
  flowType: FlowType;
  recognitions: RecognitionDto[];
  extractedNamedEntities: ExtractedNamedEntityDto[];
  messageAttachments: MessageAttachmentDto[];
}

export interface RecognitionDto {
  intentName: string;
  confidence: number;
}

export interface MessageAttachmentDto {
  messageId: string;
  category: MessageAttachmentCategory;
  contentType: string;
  extension: string;
  originalName: string;
  storageObjectName: string;
  sizeInBytes: number;
  dateCreated: Date;
  dateDeleted: Date;
  isDeleted: boolean;
}

export interface RatingDto {
  id: string;
  externalConversationId: string;
  conversationSessionId: string;
  rating: number;
  comment: string;
  dateProcessed: Date;
}

export interface ExtractedNamedEntityDto {
  name: string;
  value: string;
  synonym: string;
  type: NamedEntityType;
  sourceType: NamedEntitySourceType;
}

export interface GetConversationListInput extends PagedAndSortedResultRequestDto {
  projectId: string;
}

export interface CustomerInformationDto {
  personalInformation: CustomerPersonalInformationDto[];
  sentimentScore?: number;
  sentimentLevel?: SentimentLevel;
}

export interface CustomerPersonalInformationDto {
  key: string;
  value: string;
}

export interface RecognitionModel {
  intentName: string;
  confidence: number;
}

export interface ExtractedNamedEntityModel {
  name: string;
  value: string;
  synonym: string;
  type: NamedEntityType;
  sourceType: NamedEntitySourceType;
}

export interface JourneyFlowModel {
  messages: JourneyMessageModel[];
  flowName: string;
  flowType: FlowType;
  intentFlowColorIndex?: number;
  duration: number;
  width: number;
  minWidth: number;
}

export interface JourneyMessageModel {
  id: string;
  dateCreated: Date;
  type: MessageType;
  source: MessageSource;
  sentimentLevel: SentimentLevel;
  flowName: string;
  flowType: number;
  showCustomerAvatar: boolean;
  showAgentAvatar: boolean;
  circleClass: string;
  agentAvatarContainerClass: string;
  agentAvatarClass: string;
  duration: number;
  width: number;
  minWidth: number;
}

export const DEFAULT_CONVERSATION_LIST_CONFIG: GridConfig = {
  id: 'conversation-list',
  columns: [
    {
      name: 'date',
      header: 'Conversation::Date',
      locked: true,
      visible: true,
      sortable: false,
    },
    {
      name: 'externalConversationId',
      header: 'Conversation::ConversationId',
      locked: true,
      visible: true,
      sortable: false,
      width: 300,
    },
    {
      name: 'channelName',
      header: 'Conversation::Channel',
      locked: false,
      visible: true,
      sortable: false,
      width: 95,
    },
    {
      name: 'duration',
      header: 'Conversation::Duration',
      locked: false,
      visible: true,
      sortable: true,
      width: 120,
    },
    {
      name: 'lastIntent',
      header: 'Conversation::LastIntent',
      locked: false,
      visible: true,
      sortable: false,
    },
    {
      name: 'lastRatingScore',
      header: 'Conversation::Rating',
      locked: false,
      visible: false,
      sortable: true,
    },
    {
      name: 'averageSentimentScore',
      header: 'Conversation::CustomerSentiment',
      locked: false,
      visible: false,
      sortable: true,
    },
    {
      name: 'customerPhoneNumber',
      header: 'Conversation::CustomerInformationType:Phone',
      locked: false,
      visible: false,
      sortable: false,
    },
    {
      name: 'customerName',
      header: 'Conversation::CustomerInformationType:Name',
      locked: false,
      visible: false,
      sortable: true,
    },
    {
      name: 'customerEmailAddress',
      header: 'Conversation::CustomerInformationType:Email',
      locked: false,
      visible: false,
      sortable: false,
    },
    {
      name: 'customerTwitterAddress',
      header: 'Conversation::CustomerInformationType:Twitter',
      locked: false,
      visible: false,
      sortable: false,
    },
  ],
};
