import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ReplaySubject, Subject, filter, finalize, switchMap } from 'rxjs';
import { ConfigStateService, PermissionService, RoutesService, eLayoutType } from '@abp/ng.core';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { ProjectService } from 'src/app/projects/proxy/project.service';
import {
  ProjectFlowSummaryDto,
  ProjectFlowsAndVirtualAgentsCacheModel,
  ProjectFlowsAndVirtualAgentsDto,
} from 'src/app/projects/proxy/project.models';
import { PROJECT_FLOWS_AND_VIRTUAL_AGENTS_KEY } from '../shared.consts';
import { getStorageKey } from '../designer/utils';
import { CAI, ProjectRouteDataModel } from './cai-routes.model';
import { FlowCreateDto, FlowType, Flows } from '../designer/proxy/designer.model';
import {
  CONVERSATIONS_PATH,
  CONVERSATIONS_ROUTE_ID,
  CONVERSATIONS_ROUTE_NAME,
  CREATE_SUBFLOW_ROUTE_ID,
  CREATE_SUBFLOW_ROUTE_NAME,
  DASHBOARD_PATH,
  DASHBOARD_ROUTE_ID,
  DASHBOARD_ROUTE_NAME,
  EDIT_PATH,
  GENERATIVE_QUESTION_ANSWERING_RECOGNITIONS_PATH,
  GENERATIVE_QUESTION_ANSWERING_RECOGNITIONS_ROUTE_ID,
  GENERATIVE_QUESTION_ANSWERING_RECOGNITIONS_ROUTE_NAME,
  INSIGHTS_PREFIX_PATH,
  INSIGHTS_ROOT_ROUTE_ID,
  INSIGHTS_ROOT_ROUTE_NAME,
  INTENTS_PATH,
  NOTIFICATIONS_PATH,
  OUTBOUND_PATH,
  OUTBOUND_ROUTE_ID,
  OUTBOUND_ROUTE_NAME,
  PREMIUM_REPORTING_PATH,
  PREMIUM_REPORTING_ROUTE_NAME,
  PROJECTS_PATH,
  PROJECT_ROOT_ROUTE_ID,
  PROJECT_ROOT_ROUTE_NAME,
  PROMPTS_PREFIX_PATH,
  PROMPTS_ROOT_ROUTE_ID,
  PROMPTS_ROOT_ROUTE_NAME,
  RESPONSE_MANAGEMENT_PATH,
  RESPONSE_MANAGEMENT_ROUTE_ID,
  RESPONSE_MANAGEMENT_ROUTE_NAME,
  SCENARIO_DESIGN_PATH,
  SUBFLOW_TYPE_NAME,
  SYSTEM_MESSAGES_PATH,
  SYSTEM_MESSAGES_ROUTE_ID,
  SYSTEM_MESSAGES_ROUTE_NAME,
  VERSIONS_PATH,
  VIRTUAL_AGENTS_PATH,
  VIRTUAL_AGENTS_ROOT_ROUTE_NAME,
  VIRTUAL_AGENT_ROOT_ROUTE_ID,
  VIRTUAL_AGENT_ROOT_ROUTE_NAME,
} from './cai-routes.consts';
import { DesignerService } from '../designer/proxy/designer.service';
import { AI_FEATURE_GENERATIVE_QUESTION_ANSWERING } from 'src/app/virtual-agents/proxy/open-ai/open-ai.const';
import { OUTBOUND_FEATURE_OUTBOUND_CONVERSATIONS } from 'src/app/projects/proxy/outbound/outbound-rules.const';
import { CaiConfigService } from '../services/cai-config.service';

@Injectable({ providedIn: 'root' })
export class CaiRoutesService {
  currentProjectRouteData$ = new ReplaySubject<ProjectRouteDataModel>(1);
  showSubflowUpdateForm$ = new Subject<ProjectFlowSummaryDto>();
  hideSubflowUpdateForm$ = new Subject<void>();
  updateSubFlow$ = new Subject<{ id: string; name: string }>();
  refreshProjectRoutes$ = new Subject<void>();
  get isProjectMenuOpened(): boolean {
    return (
      // Is project edit menu
      (this.currentUrlSegments[0] === PROJECTS_PATH && this.currentUrlSegments[2] === EDIT_PATH) ||
      // Is project flow menu
      this.isProjectFlowsMenuOpened ||
      // Is virtual agent menu
      this.isVirtualAgentMenuOpened ||
      // Is other project menu
      this.currentUrlSegments[2] === DASHBOARD_PATH ||
      this.currentUrlSegments[2] === INSIGHTS_PREFIX_PATH ||
      this.currentUrlSegments[2] === PROMPTS_PREFIX_PATH ||
      this.currentUrlSegments[2] === PREMIUM_REPORTING_PATH ||
      this.currentUrlSegments[2] === NOTIFICATIONS_PATH
    );
  }
  isSubFlowSavingInProgress = false;

  currentProjectFlowsAndVirtualAgents: ProjectFlowsAndVirtualAgentsDto;

  private _currentProjectRouteData: ProjectRouteDataModel;
  get currentProjectRouteData(): ProjectRouteDataModel {
    return this._currentProjectRouteData;
  }
  private set currentProjectRouteData(value: ProjectRouteDataModel) {
    this._currentProjectRouteData = value;
    this.currentProjectRouteData$.next(value);
  }

  get currentProjectId() {
    return this.currentProjectFlowsAndVirtualAgents.projectId;
  }
  private set currentProjectId(projectId: string) {
    if (projectId !== this.currentProjectFlowsAndVirtualAgents?.projectId) {
      this.currentProjectFlowsAndVirtualAgents = {
        projectId,
        projectName: '',
        projectVersionId: '',
        isOutboundProject: false,
        flowSummaries: [],
        virtualAgentSummaries: [],
      };
    }
  }

  private get currentProjectVersionId() {
    return this.currentProjectFlowsAndVirtualAgents.projectVersionId;
  }

  private get premiumReportingRoutes() {
    return this.routesService.flat.filter(this.isPremiumReportingRoute);
  }

  private get isProjectFlowsMenuOpened(): boolean {
    return (
      this.currentUrlSegments[0] === PROJECTS_PATH &&
      this.currentUrlSegments[2] === VERSIONS_PATH &&
      this.currentUrlSegments[4]?.startsWith(SCENARIO_DESIGN_PATH) &&
      this.currentUrlSegments.length >= this.flowDesignerPageUrlSegmentsLength
    );
  }

  private get isVirtualAgentMenuOpened(): boolean {
    return (
      this.currentUrlSegments[0] === PROJECTS_PATH &&
      this.currentUrlSegments[2] === VIRTUAL_AGENTS_PATH &&
      this.currentUrlSegments.length >= this.virtualAgentDetailPageUrlSegmentsLength
    );
  }

  private currentUrlSegments: string[] = [];
  private virtualAgentDetailPageUrlSegmentsLength = 4;
  private flowDesignerPageUrlSegmentsLength = 5;

  constructor(
    private router: Router,
    private toasterService: ToasterService,
    private routesService: RoutesService,
    private configStateService: ConfigStateService,
    private confirmationService: ConfirmationService,
    private projectService: ProjectService,
    private permissionService: PermissionService,
    private designerService: DesignerService,
    private caiConfigService: CaiConfigService,
  ) {
    this.currentProjectId = '';

    this.updateSubFlow$.subscribe((flow) => this.updateSubFlow(flow));

    this.refreshProjectRoutes$.subscribe(() => this.refreshProjectRoutes());

    this.routesService.flat$.subscribe(() => {
      this.premiumReportingRoutes.forEach((route) => {
        if (this.currentProjectId) {
          const urlSegments = route.path.split('/').filter((s) => s);

          if (urlSegments[2] === PREMIUM_REPORTING_PATH) {
            urlSegments.shift();
            urlSegments.shift();
          }

          urlSegments.unshift('/projects', this.currentProjectId);

          route.path = urlSegments.join('/');
        }
      });
    });

    this.refreshRoute();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.refreshRoute();
      }
    });
  }

  isPremiumReportingRoute(route: CAI.Route) {
    return (
      PREMIUM_REPORTING_ROUTE_NAME === route.name ||
      PREMIUM_REPORTING_ROUTE_NAME === route.parentName
    );
  }

  private refreshRoute() {
    this.currentUrlSegments = this.router.url.split('/').filter((element) => element);

    if (this.isProjectMenuOpened) {
      const isProjectChanged = this.currentProjectId !== this.currentUrlSegments[1];

      if (isProjectChanged) {
        this.currentProjectId = this.currentUrlSegments[1];
        this.showNewProjectRoutes();
      } else {
        this.findAndSetCurrentProjectRouteData();
      }
    } else {
      this.currentProjectId = '';
      this.removeProjectRoutes();
      this.findAndSetCurrentProjectRouteData();
    }

    this.routesService.refresh();
  }

  private findAndSetCurrentProjectRouteData() {
    const projectRouteData: ProjectRouteDataModel = {
      projectId: this.currentProjectFlowsAndVirtualAgents.projectId,
      projectName: this.currentProjectFlowsAndVirtualAgents.projectName,
      projectVersionId: this.currentProjectFlowsAndVirtualAgents.projectVersionId,
      isOutboundProject: this.currentProjectFlowsAndVirtualAgents.isOutboundProject,
    };

    if (this.isProjectFlowsMenuOpened) {
      const flowId = this.currentUrlSegments[5];
      const currentFlow = this.currentProjectFlowsAndVirtualAgents.flowSummaries.find(
        (flow) => flow.id === flowId || (!flowId && flow.flowType === FlowType.main),
      );

      if (currentFlow) {
        projectRouteData.flowId = currentFlow.id;
        projectRouteData.flowName = currentFlow.name;
      }
    } else if (this.isVirtualAgentMenuOpened) {
      projectRouteData.virtualAgentId = this.currentUrlSegments[3];
      projectRouteData.virtualAgentName = this.currentUrlSegments[4];
      projectRouteData.intentId = this.currentUrlSegments[6];
    }

    if (JSON.stringify(projectRouteData) !== JSON.stringify(this.currentProjectRouteData)) {
      this.currentProjectRouteData = projectRouteData;
    }
  }

  private showNewProjectRoutes() {
    // Replace old projects routes with new ones from cache
    this.replaceProjectRoutes();

    // Load new projects routes
    this.refreshProjectRoutes();
  }

  private replaceProjectRoutes() {
    this.removeProjectRoutes();
    this.routesService.add(this.buildProjectRoutes(this.currentProjectId));
  }

  private refreshProjectRoutes() {
    this.projectService.getProjectFlowsAndVirtualAgents(this.currentProjectId).subscribe({
      next: (result) => {
        this.currentProjectFlowsAndVirtualAgents = result;
        this.cacheProjectFlowsAndVirtualAgents(result);
        this.replaceProjectRoutes();
        this.findAndSetCurrentProjectRouteData();
      },
    });
  }

  private removeProjectRoutes() {
    const routesToBeRemoved = this.routesService.flat
      .filter((route: CAI.Route) => route.isProjectRoute)
      .map((route) => route.name);

    this.routesService.remove(routesToBeRemoved);
  }

  private buildProjectRoutes(projectId: string): CAI.Route[] {
    const cachedValues = this.getCachedProjectFlowsAndVirtualAgents(projectId);

    const projectRootRoute: CAI.Route = {
      id: PROJECT_ROOT_ROUTE_ID,
      name: PROJECT_ROOT_ROUTE_NAME,
      layout: eLayoutType.application,
      iconClass: 'fa-sharp fa-regular fa-diagram-project',
      order: 0,
      requiredPolicy: 'Designer.Projects',
      isProjectRoute: true,
    };
    const virtualAgentRootRoute: CAI.Route = {
      id: VIRTUAL_AGENT_ROOT_ROUTE_ID,
      name: VIRTUAL_AGENT_ROOT_ROUTE_NAME,
      layout: eLayoutType.application,
      iconClass: 'fa fa-users',
      order: 1,
      requiredPolicy: 'Designer.Projects',
      isProjectRoute: true,
    };
    const routes: CAI.Route[] = [
      projectRootRoute,
      virtualAgentRootRoute,
      {
        id: PROMPTS_ROOT_ROUTE_ID,
        name: PROMPTS_ROOT_ROUTE_NAME,
        layout: eLayoutType.application,
        iconClass: 'fa-sharp fa-regular fa-message-pen',
        order: 2,
        requiredPolicy: 'Designer.Projects',
        isProjectRoute: true,
      },
      {
        id: RESPONSE_MANAGEMENT_ROUTE_ID,
        name: RESPONSE_MANAGEMENT_ROUTE_NAME,
        path: `${PROJECTS_PATH}/${projectId}/${RESPONSE_MANAGEMENT_PATH}`,
        layout: eLayoutType.application,
        order: 21,
        requiredPolicy: 'Designer.Projects',
        parentName: PROMPTS_ROOT_ROUTE_NAME,
        isProjectRoute: true,
      },
      {
        id: SYSTEM_MESSAGES_ROUTE_ID,
        name: SYSTEM_MESSAGES_ROUTE_NAME,
        path: `${PROJECTS_PATH}/${projectId}/${SYSTEM_MESSAGES_PATH}`,
        layout: eLayoutType.application,
        order: 22,
        requiredPolicy: 'Designer.Projects',
        parentName: PROMPTS_ROOT_ROUTE_NAME,
        isProjectRoute: true,
      },
      {
        id: DASHBOARD_ROUTE_ID,
        name: DASHBOARD_ROUTE_NAME,
        path: `${PROJECTS_PATH}/${projectId}/${DASHBOARD_PATH}`,
        iconClass: 'fas fa-chart-line',
        layout: eLayoutType.application,
        order: 3,
        requiredPolicy: 'Designer.Dashboard.Tenant',
        isProjectRoute: true,
      },
    ];

    const isGenerativeQuestionAnsweringFeatureEnabled = this.caiConfigService.isFeatureEnabled(
      AI_FEATURE_GENERATIVE_QUESTION_ANSWERING,
    );
    const isOutboundConversationsFeatureEnabled = this.caiConfigService.isFeatureEnabled(
      OUTBOUND_FEATURE_OUTBOUND_CONVERSATIONS,
    );
    const showConversationsMenu = this.permissionService.getGrantedPolicy('Designer.Conversations');

    if (showConversationsMenu || isGenerativeQuestionAnsweringFeatureEnabled) {
      routes.push({
        id: INSIGHTS_ROOT_ROUTE_ID,
        name: INSIGHTS_ROOT_ROUTE_NAME,
        layout: eLayoutType.application,
        iconClass: 'fas fa-comments-alt',
        order: 4,
        isProjectRoute: true,
      });

      if (showConversationsMenu) {
        routes.push({
          id: CONVERSATIONS_ROUTE_ID,
          name: CONVERSATIONS_ROUTE_NAME,
          path: `${PROJECTS_PATH}/${projectId}/${INSIGHTS_PREFIX_PATH}/${CONVERSATIONS_PATH}`,
          layout: eLayoutType.application,
          order: 41,
          requiredPolicy: 'Designer.Conversations',
          parentName: INSIGHTS_ROOT_ROUTE_NAME,
          isProjectRoute: true,
        });
      }

      if (isGenerativeQuestionAnsweringFeatureEnabled) {
        routes.push({
          id: GENERATIVE_QUESTION_ANSWERING_RECOGNITIONS_ROUTE_ID,
          name: GENERATIVE_QUESTION_ANSWERING_RECOGNITIONS_ROUTE_NAME,
          path: `${PROJECTS_PATH}/${projectId}/${INSIGHTS_PREFIX_PATH}/${GENERATIVE_QUESTION_ANSWERING_RECOGNITIONS_PATH}`,
          layout: eLayoutType.application,
          order: 42,
          requiredPolicy: 'Designer.GenerativeQuestionAnsweringReporting',
          parentName: INSIGHTS_ROOT_ROUTE_NAME,
          isProjectRoute: true,
        });
      }

      if (isOutboundConversationsFeatureEnabled) {
        routes.push({
          id: OUTBOUND_ROUTE_ID,
          name: OUTBOUND_ROUTE_NAME,
          path: `${PROJECTS_PATH}/${projectId}/${INSIGHTS_PREFIX_PATH}/${OUTBOUND_PATH}`,
          layout: eLayoutType.application,
          order: 43,
          requiredPolicy: 'Designer.OutboundReporting',
          parentName: INSIGHTS_ROOT_ROUTE_NAME,
          isProjectRoute: true,
          invisible: !(cachedValues?.projectFlowsAndVirtualAgents?.isOutboundProject ?? false),
        });
      }
    }

    if (cachedValues?.projectFlowsAndVirtualAgents) {
      const projectVersionId = cachedValues.projectFlowsAndVirtualAgents.projectVersionId;

      cachedValues.projectFlowsAndVirtualAgents.flowSummaries.forEach((flow, order) => {
        const projectFlowRoute = this.buildProjectFlowRoute(
          flow,
          projectId,
          projectVersionId,
          projectRootRoute.name,
          order,
        );

        routes.push(projectFlowRoute);
      });

      const createSubFlowRoute: CAI.Route = {
        id: CREATE_SUBFLOW_ROUTE_ID,
        name: CREATE_SUBFLOW_ROUTE_NAME,
        layout: eLayoutType.application,
        parentName: projectRootRoute.name,
        order: 1000,
        requiredPolicy: 'Designer.Projects',
        onClick: () => this.createSubFlow(),
        isProjectRoute: true,
      };

      routes.push(createSubFlowRoute);

      if (cachedValues.projectFlowsAndVirtualAgents.virtualAgentSummaries.length === 1) {
        const virtualAgent = cachedValues.projectFlowsAndVirtualAgents.virtualAgentSummaries[0];

        virtualAgentRootRoute.id = virtualAgent.id;
        virtualAgentRootRoute.path = `${PROJECTS_PATH}/${projectId}/${VIRTUAL_AGENTS_PATH}/${virtualAgent.id}/${virtualAgent.name}/${INTENTS_PATH}`;
      } else {
        virtualAgentRootRoute.name = VIRTUAL_AGENTS_ROOT_ROUTE_NAME;

        cachedValues.projectFlowsAndVirtualAgents.virtualAgentSummaries.forEach(
          (virtualAgent, order) => {
            const virtualAgentRoute: CAI.Route = {
              id: virtualAgent.id,
              name: virtualAgent.name,
              path: `${PROJECTS_PATH}/${projectId}/${VIRTUAL_AGENTS_PATH}/${virtualAgent.id}/${virtualAgent.name}/${INTENTS_PATH}`,
              layout: eLayoutType.application,
              parentName: virtualAgentRootRoute.name,
              order,
              requiredPolicy: 'Designer.Projects',
              isProjectRoute: true,
            };

            routes.push(virtualAgentRoute);
          },
        );
      }
    }

    return routes;
  }

  private buildProjectFlowRoute(
    flow: { id: string; name: string; flowType: FlowType },
    projectId: string,
    projectVersionId: string,
    parentName: string,
    order: number,
  ): CAI.Route {
    let routeActions: CAI.RouteAction[] = [];

    if (flow.flowType === FlowType.subFlow) {
      routeActions = [
        {
          name: '::Rename',
          iconClass: 'fa fa-edit text-secondary',
          requiredPolicy: 'Designer.Flows.Edit',
          onClick: () => {
            const updatedFlow = this.currentProjectFlowsAndVirtualAgents.flowSummaries.find(
              (f) => f.id === flow.id,
            );
            this.showSubflowUpdateForm$.next(updatedFlow);
          },
        },
        {
          name: 'AbpUi::Delete',
          iconClass: 'fa fa-trash text-secondary',
          requiredPolicy: 'Designer.Flows.Edit',
          onClick: () => {
            const updatedFlow = this.currentProjectFlowsAndVirtualAgents.flowSummaries.find(
              (f) => f.id === flow.id,
            );

            this.deleteSubFlow(updatedFlow);
          },
        },
      ];
    }

    return {
      id: flow.id,
      name: flow.name,
      path: `${PROJECTS_PATH}/${projectId}/${VERSIONS_PATH}/${projectVersionId}/${SCENARIO_DESIGN_PATH}/${flow.id}`,
      layout: eLayoutType.application,
      parentName: parentName,
      order,
      requiredPolicy: 'Designer.Projects',
      actions: routeActions,
      isProjectRoute: true,
    };
  }

  private getCachedProjectFlowsAndVirtualAgents(
    projectId: string,
  ): ProjectFlowsAndVirtualAgentsCacheModel {
    const storageKey = getStorageKey(this.configStateService, PROJECT_FLOWS_AND_VIRTUAL_AGENTS_KEY);
    const cachedValuesStr = localStorage.getItem(storageKey);

    return cachedValuesStr ? JSON.parse(cachedValuesStr)[projectId] : null;
  }

  private cacheProjectFlowsAndVirtualAgents(value: ProjectFlowsAndVirtualAgentsDto) {
    const storageKey = getStorageKey(this.configStateService, PROJECT_FLOWS_AND_VIRTUAL_AGENTS_KEY);
    let cachedValuesStr = localStorage.getItem(storageKey);
    let cachedValues = new Map<string, ProjectFlowsAndVirtualAgentsCacheModel>();
    if (cachedValuesStr) {
      cachedValues = JSON.parse(cachedValuesStr);
    }

    const cachedValue = cachedValues[value.projectId] ?? {};

    cachedValue.projectFlowsAndVirtualAgents = value;
    cachedValues[value.projectId] = cachedValue;
    cachedValuesStr = JSON.stringify(cachedValues);
    localStorage.setItem(storageKey, cachedValuesStr);
  }

  private createSubFlow() {
    this.isSubFlowSavingInProgress = true;

    const subFlowCount = this.currentProjectFlowsAndVirtualAgents.flowSummaries.filter(
      (flow) => flow.flowType === FlowType.subFlow,
    ).length;
    const subflowName = this.generateSubFlowName(subFlowCount + 1);

    const flowCreateDto: FlowCreateDto = {
      projectVersionId: this.currentProjectVersionId,
      name: subflowName,
      flowType: FlowType.subFlow,
    };

    this.designerService
      .createSubFlow(flowCreateDto)
      .pipe(finalize(() => (this.isSubFlowSavingInProgress = false)))
      .subscribe({
        next: (flow: Flows) => {
          this.toasterService.success('AbpUi::SavedSuccessfully');

          const parentName = this.routesService.flat.find(
            (route) => route.name === PROJECT_ROOT_ROUTE_NAME,
          ).name;
          const order =
            Math.max(
              ...this.routesService.flat
                .filter(
                  (route: CAI.Route) =>
                    route.parentName === parentName && route.id !== CREATE_SUBFLOW_ROUTE_ID,
                )
                .map((route) => route.order),
            ) + 1;

          const projectFlowRoute = this.buildProjectFlowRoute(
            flow,
            this.currentProjectId,
            this.currentProjectVersionId,
            parentName,
            order,
          );

          this.routesService.add([projectFlowRoute]);
          this.currentProjectFlowsAndVirtualAgents.flowSummaries.push({
            id: flow.id,
            name: flow.name,
            reducedName: flow.name,
            flowType: flow.flowType,
          });
          this.cacheProjectFlowsAndVirtualAgents(this.currentProjectFlowsAndVirtualAgents);

          this.router.navigate([
            PROJECTS_PATH,
            this.currentProjectId,
            VERSIONS_PATH,
            this.currentProjectVersionId,
            SCENARIO_DESIGN_PATH,
            flow.id,
          ]);
        },
      });
  }

  private generateSubFlowName(currentNumber: number) {
    const generatedName = SUBFLOW_TYPE_NAME + ' ' + currentNumber;

    const isExist = this.currentProjectFlowsAndVirtualAgents.flowSummaries.some(
      (flow) => flow.name === generatedName,
    );

    return isExist ? this.generateSubFlowName(currentNumber + 1) : generatedName;
  }

  private updateSubFlow(flow: { id: string; name: string }) {
    this.isSubFlowSavingInProgress = true;

    this.designerService
      .updateSubFlowName(flow.id, { name: flow.name })
      .pipe(finalize(() => (this.isSubFlowSavingInProgress = false)))
      .subscribe({
        next: (updatedFlow: Flows) => {
          this.toasterService.success('AbpUi::SavedSuccessfully');

          this.hideSubflowUpdateForm$.next();

          this.currentProjectFlowsAndVirtualAgents.flowSummaries.find(
            (f) => f.id === updatedFlow.id,
          ).name = updatedFlow.name;

          this.cacheProjectFlowsAndVirtualAgents(this.currentProjectFlowsAndVirtualAgents);

          const updatedRoute = this.routesService.flat.find(
            (route: CAI.Route) => route.id === updatedFlow.id,
          );

          this.routesService.patch(updatedRoute.name, { name: updatedFlow.name });
          this.findAndSetCurrentProjectRouteData();
        },
      });
  }

  private deleteSubFlow(flow: ProjectFlowSummaryDto) {
    this.confirmationService
      .warn('::DeleteConfirmationMessageWithName', '::DeleteRecordConfirmationMessage', {
        messageLocalizationParams: [flow.name],
      })
      .pipe(
        filter((status) => status === Confirmation.Status.confirm),
        switchMap(() => this.designerService.deleteSubFlow(flow.id)),
      )
      .subscribe(() => {
        const deletedRoute = this.routesService.flat.find((route) => route.name === flow.name);
        this.routesService.remove([flow.name]);
        this.currentProjectFlowsAndVirtualAgents.flowSummaries =
          this.currentProjectFlowsAndVirtualAgents.flowSummaries.filter((f) => f.id !== flow.id);
        this.cacheProjectFlowsAndVirtualAgents(this.currentProjectFlowsAndVirtualAgents);

        // Check if the current sub flow is deleted
        if (this.isProjectFlowsMenuOpened && this.currentUrlSegments[5] === flow.id) {
          const previousRoute = this.routesService.flat
            .filter(
              (route) =>
                route.parentName === deletedRoute.parentName && route.order < deletedRoute.order,
            )
            .pop();
          const previousFlow = this.currentProjectFlowsAndVirtualAgents.flowSummaries.find(
            (f) => f.name === previousRoute.name,
          );

          this.router.navigate([
            PROJECTS_PATH,
            this.currentProjectId,
            VERSIONS_PATH,
            this.currentProjectVersionId,
            SCENARIO_DESIGN_PATH,
            previousFlow.id,
          ]);
        }
      });
  }
}
