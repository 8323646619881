import {
  CustomHttpErrorHandlerService,
  CUSTOM_HTTP_ERROR_HANDLER_PRIORITY,
  ToasterService,
  Confirmation,
} from '@abp/ng.theme.shared';
import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  FLOW_VERSION_CANNOT_BE_UPDATED_ERROR_CODE,
  NLU_INTEGRATION_ERROR_CODE,
} from '../shared.consts';
import { CustomConfirmationService } from '../confirmation/custom-confirmation.service';
import { LocalizationService } from '@abp/ng.core';

@Injectable({
  providedIn: 'root',
})
export class CaiHttpErrorHandlerService implements CustomHttpErrorHandlerService {
  readonly priority = CUSTOM_HTTP_ERROR_HANDLER_PRIORITY.veryHigh;
  protected readonly toaster = inject(ToasterService);
  protected readonly confirmationService = inject(CustomConfirmationService);
  protected readonly localizationService = inject(LocalizationService);

  private error: HttpErrorResponse | undefined = undefined;

  isHttp403Error(error: HttpErrorResponse) {
    return (
      error.status === 403 && error.error.error.code !== FLOW_VERSION_CANNOT_BE_UPDATED_ERROR_CODE
    );
  }

  isHttp400Error(error: HttpErrorResponse) {
    return error.status === 400;
  }

  canHandle(error: unknown): boolean {
    if (
      error instanceof HttpErrorResponse &&
      (this.isHttp403Error(error) || this.isHttp400Error(error))
    ) {
      this.error = error;
      return true;
    }

    return false;
  }

  execute() {
    if (this.isHttp403Error(this.error)) {
      const errorMessage = this.error.error.error.message;

      if (this.error.error.error.code === NLU_INTEGRATION_ERROR_CODE) {
        const options: Partial<Confirmation.Options> = {
          hideYesBtn: true,
          icon: 'fa fa-circle-exclamation',
        };
        const title = this.localizationService.instant('::Warning');

        this.confirmationService.warn(errorMessage, title, options, false);
      } else {
        this.toaster.error(errorMessage);
      }
    }

    if (this.isHttp400Error(this.error)) {
      this.toaster.error(this.error.error?.error?.details);
    }
  }
}
