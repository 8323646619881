import { FullAuditedEntityDto } from '@abp/ng.core';
export interface DynamicParameter {
  [key: string]: DynamicParameterItem[];
}

export interface DynamicParameterItem {
  key: string;
  value: string;
}

export interface FlowDtoWithIndex extends FlowDto {
  index: number;
}
export interface FlowDto extends FullAuditedEntityDto<string> {
  id: string;
  flowType: FlowType;
  json?: string;
  name: string;
  parameters?: string;
  projectVersionId?: null;
  projectName: string;
  projectId?: null;
  isReadonly: boolean;
}

export interface Flows {
  projectVersionId?: string;
  flowId?: string;
  id: string;
  json?: string | go.Model;
  name: string;
  index?: number;
  flowType: FlowType;
  parameters?: string;
  isReadonly: boolean;
}

export interface FlowModel {
  linkFromPortIdProperty: string;
  linkToPortIdProperty: string;
  nodeCategoryProperty: string;
  nodeDataArray: NodeModel[];
  linkDataArray: LinkModel[];
  paletteVersion: string;
  scale: number;
  position: string;
}

export interface NodeModel {
  isFlowNodeOnly: boolean;
  isActionNodeOnly: boolean;
  category: string;
  description: string;
  id: number;
  inputs: NodeFieldModel[];
  order: number;
  outputs: NodeFieldModel[];
  text: string;
  name: string;
  displayName: string;
  inputRules: NodePortModel[];
  outputRules: NodePortModel[];
  hasResponseCode: boolean;
  isObsolete: boolean;
  isEnabled: boolean;
  categoryName: string;
  categoryOrder: number;
  isValid: boolean;
  typeId: string;
  channels: NodeChannelModel[];
  showChannels: boolean;
  key: number;
  loc: string;
  paletteVisibility: string;
}

export interface LinkModel {
  from: number;
  to: number;
  fromPort: string;
  toPort: string;
  points: number[];
}

export interface NodePortModel {
  name?: string;
  portColor?: string;
  portId?: string;
}

export interface NodeFieldRuleModel {
  name: string;
  conditions: NodeFieldRuleConditionModel[];
}

export interface NodeFieldRuleConditionModel {
  fieldName: string;
  operator: string;
  value: any;
}

export interface NodeFieldModel {
  name?: string;
  isMandatory: boolean;
  description?: string;
  extraProperties: string;
  fieldType: string;
  value?: string;
  action?: string;
  type?: string;
  range?: string[];
  step?: number;
  options?: string[];
  json?: string;
  tooltip?: string;
  code?: number;
  typeId?: string;
  order?: number;
  placeholder?: string;
  col?: number;
}

export interface NodeChannelModel {
  typeId: string;
  name: string;
  inputs: NodeFieldModel[];
  responses: NodeChannelResponseModel[];
  extraProperties: string;
}

export interface NodeChannelResponseModel {
  extraProperties: string;
  typeId: string;
  name: string;
  order: number;
  isMandatory: boolean;
  description: string;
  value: string;
  placeholder: string;
  action: string;
  type: string;
  step: number;
  range: any[];
  options: any[];
  tooltip: string;
  col: number;
  fieldType: string;
}

export interface UiPaletteOutputDto {
  id: number;
  category?: string;
  maxLinks: number;
  name?: string;
  text?: string;
  inputs?: NodeFieldModel[];
  inputRules?: NodePortModel[];
  outputRules?: NodePortModel[];
  source?: string;
  outputs?: NodeFieldModel[];
  order: number;
  description?: string;
  type?: string;
  isActionNodeOnly: boolean;
  isFlowNodeOnly: boolean;
  hasResponseCode: boolean;
}

export interface ContextDto {
  id: string;
  key: string;
  value: string;
  type: ContextType;
}

export interface ContextEditDto {
  id: string;
  key: string;
  value: string;
  type: ContextType;
  creationStatus: CreationStatusDto;
}

export interface ContextCreateDto {
  key: string;
  value: string;
  type: ContextType;
}

export interface ContextUpdateDto {
  id: string;
  key: string;
  value: string;
  type: ContextType;
}

export enum ContextType {
  dynamic,
  static,
  entity,
  intentEntity,
  projectEntity,
  custom,
  binaryBase64,
}

export interface DeletionStatusDto {
  isDeletable: boolean;
  validationMessage: string;
}

export interface CreationStatusDto {
  duplicationExists: boolean;
  validationMessage: string;
}

export interface FlowCreateDto {
  projectVersionId: string;
  name: string;
  flowType: FlowType;
  parameters?: string;
}

export interface FlowUpdateDto {
  id?: string;
  name?: string;
  projectId?: string;
  flows?: Flows[];
}

export interface FlowNameUpdateDto {
  name?: string;
}

export interface MandatoryFieldsResponseDto {
  isValid?: boolean;
  message?: string[];
}

export interface ProjectVersionDto extends FullAuditedEntityDto<string> {
  name?: string;
  projectId?: string;
  projectName?: string;
  hasSurvey?: boolean;
  hasPostSessionFlow?: boolean;
  version: number;
  primaryLanguageCode: string;
  additionalLanguageCodes: string[];
  flows?: FlowDto[];
  customDataKeys?: CustomDataKeyDto[];
}

export interface CustomDataKeyDto extends FullAuditedEntityDto<string> {
  key?: string;
  description?: string;
  projectVersionId?: null;
}

export interface CustomDataKeyUpdateDto {
  id?: string;
  key?: string;
  description?: string;
}

export interface CustomDataKeyCreateDto {
  key?: string;
  description?: string;
}

export interface TextGroupResponseModel {
  texts: ResponseTextWithIdModel[];
  algorithm: number;
  id: string;
}

export interface QuickReplyResponseModel {
  title: ResponseTextWithIdModel;
  replies: QuickReplyModel[];
  id: string;
}

export interface QuickReplyModel {
  title: ResponseTextWithIdModel;
  type: string;
  value: ResponseTextWithIdModel;
  responseArray: ResponseTextWithIdModel;
  maxButtonCount: string;
  buttonType: ButtonType;
  index: number;
}

export enum ReplyType {
  SendAMessage,
}

export enum ButtonType {
  defaultButton = '0',
  dynamicButton = '1',
}

export type ChannelAudioValue = ChannelAttachmentValue;

export type ChannelFileValue = ChannelAttachmentValue;

export enum AttachmentType {
  Audio,
  File,
}

export interface ChannelAttachmentValue {
  source: string;
  filePath: string;
  fileName: string;
  key: string;
}

export interface ChannelWaitValue {
  timeout: string;
}

export interface ChannelLocationValue {
  parameters: ChannelLocationValueElement[];
}
export interface ChannelCardsValue {
  cards: ChannelCard[];
  parameters: ChannelCardElement[];
}

export interface ChannelLocationValueElement {
  key: string;
  value: string;
  isMandatory: boolean;
}

interface ChannelCardElement {
  key: string;
  value: string;
}

export interface ChannelCard {
  cardType: CardType;
  responseArray: string;
  maxCardCount: number;
  imageUrl: string;
  title: string;
  subtitle: string;
  description: string;
  quickReplies: string;
}

export enum CardType {
  defaultCard = '0',
  dynamicCard = '1',
}

export enum InputStatus {
  VALID,
  INVALID,
}

export enum FlowType {
  main,
  survey,
  subFlow,
  fallbackSR,
  actionFlow,
  postSessionFlow,
}

export interface RenameFlowCompletedEventArgs {
  flowId: string;
  newName: string;
}

export interface ProjectVersionSubFlowDto {
  name: string;
  isEnabled: boolean;
  tooltip: string;
}

export interface ResponseTextWithIdModel {
  id: string;
  text: string;
}
