<div class="container">
  <div class="row">
    <div class="col-lg-10 col-md-11 mx-auto">
      <div class="d-flex flex-column justify-content-center min-vh-100 align-items-center">
        <div class="card">
          <div class="card-body p-lg-5">
            <div class="row">
              <div class="col-auto d-flex align-items-center">
                <i
                  class="fa fa-frown-o text-danger mb-3 mb-md-0 mx-3 mx-md-4"
                  style="font-size: 12em"
                ></i>
              </div>
              <div class="col flex-center ps-lg-4">
                <div class="status-content">
                  <h1>{{ status() }}</h1>
                  <h2 class="text-danger mb-0">
                    {{ errorStatusText() | abpLocalization }}
                    <h3 class="text-muted">{{ errorDetail() | abpLocalization }}</h3>
                  </h2>

                  <p class="mt-3 mb-4"></p>
                  <a
                    (click)="goHome()"
                    class="go-home-button btn btn-primary"
                    data-id="go-home-button"
                  >
                    {{ 'AbpUi::GoHomePage' | abpLocalization }}
                  </a>

                  @if (lastNavigation() && status() !== 403) {
                    <a
                      class="go-back-button mt-4 mb-1 d-block"
                      (click)="goBack()"
                      data-id="go-back-button"
                    >
                      <i class="fa fa-long-arrow-left me-1" aria-hidden="true"></i>
                      {{ 'AbpUi::GoBack' | abpLocalization }}
                    </a>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
