import { QuillValueModel } from './rich-text-editor.model';

export const RICH_TEXT_EDITOR_EMPTY_VALUE: any = { ops: [{ insert: '\n' }] };
export const RICH_TEXT_VALUE_WITH_LAST_ATTACHMENT_PATH: any = {
  ops: [
    {
      insert: {
        mention: {
          denotationChar: '',
          key: 'LastAttachmentPath',
          value: 'LastAttachmentPath',
          type: 'static',
        },
      },
    },
  ],
};

export const convertRichTextToPlainText = (
  str: string,
  replaceMentionsWithZeroWidthSpaces: boolean = false,
) => {
  let quillValue: QuillValueModel;
  try {
    quillValue = JSON.parse(str);
  } catch {}

  if (typeof quillValue === 'object' && quillValue.ops && quillValue.ops.length) {
    let convertedQuillValue = '';

    const lastOp = quillValue.ops[quillValue.ops.length - 1];

    if (typeof lastOp.insert === 'string') {
      lastOp.insert = clearNewLineSuffix(lastOp.insert);
    }

    quillValue.ops.forEach((op) => {
      if (typeof op.insert === 'string') {
        convertedQuillValue += op.insert;
      } else if (typeof op.insert === 'object') {
        if (replaceMentionsWithZeroWidthSpaces) {
          convertedQuillValue += '\u200B';
        } else {
          convertedQuillValue += '%' + op.insert.mention.key;
        }
      }
    });

    return convertedQuillValue;
  }

  return str;
};

export const clearNewLineSuffix = (value: string): string => {
  if (value) {
    if (value.endsWith('<br>')) {
      value = clearNewLineSuffix(value.slice(0, -4));
    } else if (value.endsWith('\r\n')) {
      value = clearNewLineSuffix(value.slice(0, -2));
    } else if (value.endsWith('\n')) {
      value = clearNewLineSuffix(value.slice(0, -1));
    }
  }

  return value;
};
